:where(media-player [data-media-slider]) {
  -webkit-tap-highlight-color: transparent;

  --width: var(--media-slider-width, 100%);
  --height: var(--media-slider-height, 48px);

  --thumb-size: var(--media-slider-thumb-size, 15px);
  --thumb-focus-size: var(--media-slider-focused-thumb-size, calc(var(--thumb-size) * 1.1));

  --track-width: var(--media-slider-track-width, 100%);
  --track-height: var(--media-slider-track-height, 5px);
  --track-focus-width: var(--media-slider-focused-track-width, var(--track-width));
  --track-focus-height: var(--media-slider-focused-track-height, calc(var(--track-height) * 1.25));

  width: var(--width);
  height: var(--height);
  /** Prevent thumb flowing out of slider. */
  margin: 0 calc(var(--thumb-size) / 2);
  contain: layout style;
}

:where(media-player [data-media-slider] > shadow-root) {
  display: contents;
}

:where(media-player [data-media-slider] [part~='track']) {
  z-index: 0;
  position: absolute;
  width: var(--track-width);
  height: var(--track-height);
  top: 50%;
  left: 0;
  border-radius: var(--media-slider-track-border-radius, 1px);
  transform: translateY(-50%) translateZ(0);
  background-color: var(--media-slider-track-bg, rgb(255 255 255 / 0.3));
  contain: strict;
}

:where(media-player [data-media-slider] [part='track']) {
  backdrop-filter: blur(2px);
}

:where(media-player [data-media-slider][data-focus] [part='track']) {
  outline-offset: var(--thumb-size);
}

:where(media-player [data-media-slider]:not([data-chapters])[data-interactive] [part*='track']) {
  width: var(--track-focus-width);
  height: var(--track-focus-height);
}

:where(media-player [data-media-slider] [part~='track-fill']) {
  z-index: 2; /** above track and track progress. */
  background-color: var(--media-slider-track-fill-bg, #fafafa);
  width: var(--slider-fill-percent);
  will-change: width;
}

:where(media-player[data-live] media-time-slider [part~='track-fill']) {
  background-color: var(--media-slider-track-fill-live-bg, #dc2626);
}

:where(media-time-slider [part~='track-progress']) {
  z-index: 1; /** above track. */
  left: 0;
  width: var(--media-buffered-percent, 0%);
  will-change: width;
  background-color: var(--media-slider-track-progress-bg, rgb(255 255 255 / 0.5));
}

:where(media-player [data-media-slider] [part='thumb-container']) {
  z-index: 2; /** above track fill. */
  position: absolute;
  top: 0;
  left: var(--slider-fill-percent);
  width: var(--thumb-size);
  height: 100%;
  transform: translateX(-50%) translateZ(0); /** re-center along x-axis. */
  will-change: left;
  pointer-events: none;
  contain: layout size style;
}

:where(media-player [data-media-slider][data-dragging] [part='thumb-container']) {
  /* left: var(--slider-pointer-percent); */
}

:where(media-player [data-media-slider] [part='thumb']) {
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 0;
  contain: strict;
  width: var(--thumb-size);
  height: var(--thumb-size);
  border: var(--media-slider-thumb-border, 1px solid #cacaca);
  border-radius: var(--media-slider-thumb-border-radius, 9999px);
  background-color: var(--media-slider-thumb-bg, #fff);
  transform: translateY(-50%) translateZ(0);
  transition: opacity 0.15s ease-in;
  pointer-events: none;
}

:where(media-player)
  :where([data-media-slider][data-dragging], [data-media-slider][data-focus])
  :where([part='thumb']) {
  box-shadow: var(--media-slider-focused-thumb-shadow, 0 0 0 4px hsla(0, 0%, 100%, 0.4));
}

:where(media-player [data-media-slider][data-interactive] [part='thumb']) {
  opacity: 1;
  transition: var(--media-slider-thumb-transition, opacity 0.2s ease-in, box-shadow 0.2s ease);
}

:where(media-player [data-media-slider][data-dragging] [part='thumb']) {
  width: var(--thumb-focus-size);
  height: var(--thumb-focus-size);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Preview
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-player [data-media-slider] [slot='preview']) {
  display: flex;
  position: absolute;
  left: var(--preview-left);
  bottom: calc(100% + var(--media-slider-preview-gap, -4px));
  flex-direction: column;
  align-items: center;
  opacity: 0;
  background-color: var(--media-slider-preview-bg);
  border-radius: var(--media-slider-preview-border-radius, 2px);
  pointer-events: none;
  transform: translateX(-50%) translateZ(0);
  transition: opacity 0.2s ease-out;
  will-change: left, opacity;
  contain: layout paint style;
}

:where(media-player [data-media-slider][data-interactive] [slot='preview']) {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

:where(media-player [data-media-slider] media-slider-value) {
  padding: var(--media-slider-value-padding, 1px 10px);
  color: var(--media-slider-value-color, white);
  background-color: var(--media-slider-value-bg, black);
  border-radius: var(--media-slider-value-border-radius, 2px);
  border: var(--media-slider-value-border);
}

:where(
    media-slider-video:not([aria-hidden='true']) + media-slider-value,
    media-slider-video:not([aria-hidden='true']) + [part='chapter-title'],
    media-slider-thumbnail:not([aria-hidden='true']) + media-slider-value,
    media-slider-thumbnail:not([aria-hidden='true']) + [part='chapter-title']
  ) {
  margin-top: var(--media-slider-value-gap, 8px);
}

:where(media-player:not([data-can-play]) media-time-slider media-slider-value) {
  display: none;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Vertical
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-player [data-media-slider][aria-orientation='vertical']) {
  --width: var(--media-slider-width, 48px);
  --height: var(--media-slider-height, 100%);

  --track-width: var(--media-slider-track-width, 4px);
  --track-height: var(--media-slider-track-height, 100%);
  --track-focus-width: var(--media-slider-focused-track-width, calc(var(--track-width) * 1.25));
  --track-focus-height: var(--media-slider-focused-track-height, var(--track-height));

  /** Prevent thumb flowing out of slider. */
  margin: calc(var(--thumb-size) / 2) 0;
}

:where(media-player [data-media-slider][aria-orientation='vertical'] [part*='track']) {
  top: unset;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateZ(0);
}

:where(media-player [data-media-slider][aria-orientation='vertical'] [part~='track-fill']) {
  width: var(--track-width);
  height: var(--slider-fill-percent);
  will-change: height;
  transform: translateX(-50%) translateZ(0);
}

:where(media-player [data-media-slider][aria-orientation='vertical'] [part~='track-progress']) {
  top: unset;
  bottom: 0;
  width: var(--track-width);
  height: var(--media-buffered-percent, 0%);
  will-change: height;
}

:where(media-player [data-media-slider][aria-orientation='vertical'] [part='thumb-container']) {
  top: unset;
  bottom: var(--slider-fill-percent);
  left: 50%;
  width: 100%;
  will-change: bottom;
  transform: translateX(-50%) translateZ(0);
}

:where(
    media-player
      [data-media-slider][aria-orientation='vertical'][data-dragging]
      [part='thumb-container']
  ) {
  top: unset;
  /* bottom: var(--slider-pointer-percent); */
}

:where(media-player [data-media-slider][aria-orientation='vertical'] [part='thumb']) {
  top: unset;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%) translateZ(0);
}

:where(media-player [data-media-slider][aria-orientation='vertical'] [slot='preview']) {
  top: unset;
  left: calc(100% + var(--media-slider-vertical-preview-gap, 4px));
  bottom: var(--preview-bottom);
  will-change: bottom;
  transform: translateY(50%) translateZ(0);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Chapters
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-time-slider [part='chapters']) {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  contain: layout style;
}

:where(media-time-slider [part='chapter-container']) {
  display: flex;
  align-items: center;
  width: 0%;
  height: 100%;
  margin-right: 2px;
  contain: layout style;
}

:where(media-time-slider [part='chapter-container']:last-child) {
  margin-right: 0;
}

:where(media-time-slider [part='chapter']) {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--track-height);
  will-change: height, transform;
  contain: layout style;
  border-radius: var(--media-slider-track-border-radius, 1px);
}

@media (pointer: fine) {
  :where(media-time-slider [part='chapter-container']:hover [part='chapter']) {
    transform: var(--media-slider-chapter-hover-transform, scaleY(2));
    transition: var(
      --media-slider-chapter-hover-transition,
      transform 0.1s cubic-bezier(0.4, 0, 1, 1)
    );
  }
}

:where(media-time-slider [part='chapter-title']) {
  font-family: var(--media-font-family, sans-serif);
  font-size: var(--media-slider-chapter-title-font-size, 14px);
  color: var(--media-slider-chapter-title-color, #f5f5f5);
  background-color: var(--media-slider-chapter-title-bg);
}
